import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "How To Clean All Your node_modules Inside Your Machine",
  "author": "tzookb",
  "type": "post",
  "date": "2019-12-24T13:50:08.000Z",
  "url": "/clean-all-node-modules-on-your-computer",
  "featuredImage": "./clean-node-modeuls.png",
  "categories": ["javascript", "tricks"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`If you worked with javascript for quite some time, you probably have many projects spread around your computer.
Recently my mac is super low on memory and I could not find anything to remove.`}</p>
    <p>{`So I started "debugging" through my folders and I noticed a lot of MBs inside all my projects node_modules.`}</p>
    <p>{`In the bottom line I removed 30 GB !`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# this will output the memory size node_modules take
find . -name "node_modules" -type d -prune -print | xargs du -chs

# this will remove all node_modules
find . -name 'node_modules' -type d -prune -print -exec rm -rf '{}' \\;
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      